import React from "react";

function Admin() {
  return (
    <div>
      <h2>Admin Section</h2>
      {/* Content for the admin section */}
    </div>
  );
}

export default Admin;
