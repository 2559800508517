import React, { useState } from "react";
import {
  FaArrowDown,
  FaArrowUp,
  FaArrowLeft,
  FaDollarSign,
  FaShoppingBag,
} from "react-icons/fa";
// import firebase from "../config"; // Import Firebase connection

import "./Careers.css";
import "./Home.css";

const jobData = [
  // {
  //   id: 1,
  //   title: "Senior Software Engineer",
  //   salary: "23K-80K",
  //   time: "/Year",
  //   description:
  //     "This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information.",
  //   additionalInfo: ["Fulltime", "Engineering", "San Francisco"],
  // },
  // {
  //   id: 2,
  //   title: "Frontend Developer",
  //   salary: "20K-80K",
  //   time: "/Year",
  //   description:
  //     "This is the job description for the Frontend Developer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information.",
  //   additionalInfo: ["Fulltime", "Engineering", "Remote"],
  // },
  // {
  //   id: 3,
  //   title: "Frontend Developer",
  //   salary: "20K-80K",
  //   time: "/Year",
  //   description:
  //     "This is the job description for the Frontend Developer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information.",
  //   additionalInfo: ["Fulltime", "Engineering", "Remote"],
  // },
  // {
  //   id: 4,
  //   title: "Frontend Developer",
  //   salary: "20K-80K",
  //   time: "/Year",
  //   description:
  //     "This is the job description for the Frontend Developer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information.",
  //   additionalInfo: ["Fulltime", "Engineering", "Remote"],
  // },
  // {
  //   id: 5,
  //   title: "Frontend Developer",
  //   salary: "20K-80K",
  //   time: "/Year",
  //   description:
  //     "This is the job description for the Frontend Developer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information. This is the job description for the Senior Software Engineer position. Click the box to reveal more information.",
  //   additionalInfo: ["Fulltime", "Engineering", "Remote"],
  // },
  // Add more job objects as needed
];

const JobBox = ({ job }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [file, setFile] = useState(null);
  const [filePath, setFilePath] = useState("");

  const toggleDescription = () => {
    setShowDescription((prevState) => !prevState);
  };

  const applyNow = async (id) => {
    if (!showDescription) {
      toggleDescription();
    } else {
      const title = document.getElementById(`title_${id}`).value;
      const email = document.getElementById(`email_${id}`).value;
      const name = document.getElementById(`name_${id}`).value;
      const city = document.getElementById(`city_${id}`).value;

      const fileInput = document.getElementById(`pdf_${id}`);
      const selectedFile = fileInput.files[0];

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (title.trim() === "") {
        alert("Title cannot be empty!");
      } else if (email.trim() === "") {
        alert("Email cannot be empty!");
      } else if (!emailRegex.test(email)) {
        alert("Invalid email format!");
      } else if (name.trim() === "") {
        alert("Name cannot be empty!");
      } else if (city.trim() === "") {
        alert("City cannot be empty!");
      } else if (!selectedFile) {
        alert("Please select your CV!");
      } else {
      }
    }
  };

  return (
    <div className={`job-box ${showDescription ? "open" : ""}`}>
      <div className="job-info" onClick={toggleDescription}>
        <div className="job-title">
          <FaShoppingBag className="icon maricon" /> {job.title}
        </div>
        <div className="job-salary">
          <div className="rountbox">
            <FaDollarSign className="icon maricon2" />{" "}
          </div>{" "}
          Rs: {job.salary} <span className="timetx">{job.time}</span>
        </div>
      </div>
      <div className="hiddinput">
        <input id={`title_${job.id}`} value={job.title} />
      </div>
      {showDescription && (
        <div className="job-description">
          <p>{job.description}</p>
          <div className="row mt-4 mb-2">
            <div className="col-md-3 col-12 mb-2">
              <div className="input-container">
                <input
                  id={`email_${job.id}`}
                  className="input"
                  type="email"
                  placeholder="Email"
                />
                <span className="input-border"></span>
              </div>
            </div>
            <div className="col-md-3 col-12 mb-2">
              <div className="input-container">
                <input
                  id={`name_${job.id}`}
                  className="input"
                  type="text"
                  placeholder="Name"
                />
                <span className="input-border"></span>
              </div>
            </div>
            <div className="col-md-3 col-12 mb-2">
              <div className="input-container">
                <input
                  id={`city_${job.id}`}
                  className="input"
                  type="text"
                  placeholder="City"
                />
                <span className="input-border"></span>
              </div>
            </div>
            <div className="col-md-3 col-12 mb-2">
              <div className="input-container">
                <input
                  id={`pdf_${job.id}`}
                  className="input"
                  type="file"
                  accept="application/pdf"
                />
                <span className="input-border"></span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="additional-info">
        {job.additionalInfo.map((info, index) => (
          <div key={index} className="info-item" onClick={toggleDescription}>
            {info}
          </div>
        ))}
        <div className="apply-button">
          <button onClick={() => applyNow(job.id)}>Apply Now</button>
        </div>
      </div>
    </div>
  );
};

const Careers = () => {
  return (
    <div className="bg_web">
      {/* Header */}
      <div className="header">
        <div className="hedNav row">
          <a href="/" className="mb-1">
            <FaArrowLeft className="icon backicon" />
          </a>
          <a href="/" className="mb-1">
            <span className="backtx">Back</span>
          </a>
        </div>

        <img
          className="header-image"
          src="../images/careers_hed.jpg" // Replace with your header image URL
          alt="Header"
        />
        <span className="hettx">Careers</span>
      </div>

      <div className="careers-container">
        {/* Job Boxes */}
        {jobData.map((job) => (
          <JobBox key={job.id} job={job} />
        ))}

        {jobData.length == 0 && (
          <div className="row">
            <div className="col-12 all_center bg_full">
              <h1>NO CAREERS</h1>
            </div>
          </div>
        )}
      </div>

      <section class="info_section layout_padding2-top">
          {/* <div class="container">
            <div class="info_form">
              <h4>Our subscribes</h4>
              <form action="">
                <input type="text" placeholder="Enter your email" />
                <div class="d-flex justify-content-end">
                  <button>subscribe</button>
                </div>
              </form>
            </div>
          </div> */}
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <h6>About Us</h6>
                <p className="tx_justy">
                  At Logiccodex, we're dedicated to driving innovation
                  through technology. With a passion for excellence, we craft
                  software solutions that empower businesses to thrive in the
                  digital age. Our team of experts is committed to delivering
                  tailor-made, user-centric products that streamline operations
                  and foster growth. Join us on this journey of transformation
                  and discover the power of cutting-edge software.
                </p>
              </div>
              <div class="col-md-3 offset-md-1">
                <h6>Menus</h6>
                <ul>
                  <li class=" active">
                    <a class="" href="#">
                      Home <span class="sr-only">(current)</span>
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#about-section">
                      About Us{" "}
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#portfolio-section">
                      Our Projects
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#services-section">
                      Services{" "}
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#testimonials-section">
                      Review
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="/Careers">
                      Careers
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#contact-section">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-md-4">
                <h6>Contact Us</h6>
                <div class="info_link-box">
                  <a>
                    <span className="icon-room d-block h5  mt-2"></span>
                    <span className="amrlef">Colombo. sri lanka</span>
                  </a>
                  <a>
                    <span className="icon-phone d-block h5  mt-2"></span>
                    <span className="amrlef">+94 786718002</span>
                  </a>
                  <a>
                    <span className="icon-mail_outline d-block h5  mt-2"></span>
                    <span className="amrlef"> logiccodex@gmail.com</span>
                  </a>
                </div>
                <div class="info_social">
                  <div className="bg_rool">
                    <a href="https://www.facebook.com/UniversityLifeOfficiall12?mibextid=ZbWKwL" className="pt-2">
                      <span className="icon-facebook iconcolofoot"></span>
                    </a>
                  </div>
                  <div className="bg_rool">
                    <a href="#" className="pt-1">
                      <span className="icon-twitter iconcolofoot"></span>
                    </a>
                  </div>
                  <div className="bg_rool">
                    <a href="https://instagram.com/logiccodex?igshid=MzRlODBiNWFlZA==" className="pt-1">
                      <span className="icon-instagram iconcolofoot"></span>
                    </a>
                  </div>
                  <div className="bg_rool">
                    <a href="#" className="pt-1">
                      <span className="icon-linkedin iconcolofoot"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="container-fluid footer_section ">
          <p>
            &copy; 2023 All Rights Reserved.
            <a href="#"> LOGICCODEX</a>
          </p>
        </section>
    </div>
  );
};

export default Careers;
