import React from "react";

function AdminSettings() {
  return (
    <div>
      <h2>Admin Settings</h2>
      {/* Content for admin settings */}
    </div>
  );
}

export default AdminSettings;
