import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Home from "./page/Home";
import Careers from "./page/Careers";
import Admin from "./admin/Admin";
import AdminLoginPage from "./admin/AdminLoginPage";
import AdminSettings from "./admin/AdminSettings";

function App() {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="Careers" element={<Careers />} />

      <Route
        path="admin"
        element={
          isAdminLoggedIn ? (
            <>
              <Route index element={<Admin />} />
              <Route path="settings" element={<AdminSettings />} />{" "}
              {/* New route */}
            </>
          ) : (
            <AdminLoginPage setIsAdminLoggedIn={setIsAdminLoggedIn} />
          )
        }
      />
    </Routes>
  );
}

export default App;
