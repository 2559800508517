import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import { PushIn } from "pushin";
import ReactDOM from "react-dom";
import { Carousel } from "react-responsive-carousel";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Slider from "react-slick";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";

function Home() {
  const tearm = [
    {
      img: "../images/person_5.jpg",
      name: "Kaiara Spencer",
      role: "Product Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "#",
      instagram: "#",
    },
    {
      img: "../images/person_1.jpg",
      name: "Dave Simpson",
      role: "Product Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "#",
      instagram: "#",
    },

    {
      img: "../images/person_5.jpg",
      name: "Ben Thompson",
      role: "Product Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "#",
      instagram: "#",
    },

    {
      img: "../images/person_1.jpg",
      name: "Kyla Stewart",
      role: "Product Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "#",
      instagram: "#",
    },

    {
      img: "../images/person_5.jpg",
      name: "Dave Simpson",
      role: "Product Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "#",
      instagram: "#",
    },

    {
      img: "../images/person_1.jpg",
      name: "Ben Thompson",
      role: "Product Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "#",
      instagram: "#",
    },

    {
      img: "../images/person_5.jpg",
      name: "Chris Stewart",
      role: "Product Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "#",
      instagram: "#",
    },
    {
      img: "../images/person_1.jpg",
      name: "Kaiara Spencer",
      role: "Product Manager",
      facebook: "#",
      twitter: "#",
      linkedin: "#",
      instagram: "#",
    },
  ];

  const product = [
    {
      img1: "../images/project/iotP1_01.jpg",
      img2: "../images/project/iotP1_02.jpg",
      img3: "../images/project/iotP1_03.jpg",
      img4: "../images/project/iotP1_04.jpg",
      title: "Maritime Guardian Network",
      description:
        "The Maritime Guardian Network is a cutting-edge IoT initiative enabling uninterrupted communication between seafaring vessels and onshore control centers. Employing advanced mesh networking and satellite technology, this system ensures vital data transmission, enhancing maritime safety and enabling efficient coordination, even in internet-deprived marine environments.",
      status: "iot fyp",
    },
    {
      img1: "../images/project/webP1_01.png",
      img2: "../images/project/webP1_02.png",
      img3: "../images/project/webP1_03.png",
      img4: "../images/project/webP1_04.png",
      title: "JHM Loyalty Web",
      description:
        'Explore the official website of Loyalty Cards, built with React.js and powered by Firebase for seamless data management. All other transactions are easily handled through the Loyalty Cards mobile app, while the website displays comprehensive company information. Visit the website now for a closer look.  <a href="https://www.jhm.lk/" target="_blank">www.jhm.lk</a>',
      status: "web",
    },
    {
      img1: "../images/project/appP1_01.png",
      img2: "../images/project/appP1_02.png",
      img3: "../images/project/appP1_03.png",
      img4: "../images/project/appP1_04.png",
      title: "JHM Loyalty App",
      description:
        ' We have developed a user-friendly loyalty card app using Android Studio, catering to both admin and user needs. Powered by Firebase for seamless database management, our app facilitates user registration and hassle-free loyalty card usage. For details, visit the provided link.  <a href="https://play.google.com/store/apps/details?id=com.loyalty.cards&pcampaignid=web_share" target="_blank">www.play/loyalty</a>',
      status: "app",
    },
    {
      img1: "../images/project/uiuxP1_01.png",
      img2: "../images/project/uiuxP1_02.png",
      img3: "../images/project/uiuxP1_03.png",
      img4: "../images/project/uiuxP1_04.png",
      title: "JHM Loyalty UI/UX",
      description:
        "A Loyalty Card Company previously crafted a stunning, user-friendly app using Figma's UI/UX platform. The app features a creative and seamless design, catering to both light and dark mode preferences, ensuring an exceptional user experience.",
      status: "uiux",
    },
    {
      img1: "../images/project/iotP2_01.jpg",
      img2: "../images/project/iotP2_02.jpg",
      img3: "../images/project/iotP2_03.jpg",
      img4: "../images/project/iotP2_01.jpg",
      title: "Vision-Enhanced Mobility Aid",
      description:
        "Innovative mobility solution utilizes Raspberry Pi camera technology to empower individuals without eye pain, enabling safe road travel by providing real-time visual information. The 'Vision-Enhanced Mobility Aid' offers enhanced independence and security, fostering a more inclusive and accessible world.",
        status: "iot fyp",
    },
    {
      img1: "../images/project/appP2_01.png",
      img2: "../images/project/appP2_02.png",
      img3: "../images/project/appP2_03.png",
      img4: "../images/project/appP2_04.png",
      title: "Railway Tracking System",
      description:
        "Track trains in real-time with our app. Stay informed as it monitors incoming trains, providing location-based updates as they approach the station, ensuring a seamless travel experience.",
        status: "app fyp",
    },
    {
      img1: "../images/project/appP3_01.png",
      img2: "../images/project/appP3_02.png",
      img3: "../images/project/appP3_03.png",
      img4: "../images/project/appP3_04.png",
      title: "Web Sayura App",
      description:
        'web sayura mobile app is an educational app. It is designed as user friendly. All the programs are conducted online mode and this app is made for students to study in a wide variety of subjects. To get more details go to the link below.  <a href="https://play.google.com/store/apps/details?id=com.websayuraapp.websayura&pcampaignid=web_share" target="_blank">www.play/Web_Sayura</a>',
      status: "app",
    },
    {
      img1: "../images/project/mlP1_01.png",
      img2: "../images/project/mlP1_03.png",
      img3: "../images/project/mlP1_04.png",
      img4: "../images/project/mlP1_02.png",
      title: "Solar Dust Finder (AI).",
      description:
        'This AI is designed to measure the amount of dust on solar panels. Here, when a picture of the respective solar panel is uploaded, Ai checks the amount of dust on it and notifies the concerned person via email with other related information and the amount of dust as a percentage.',
      status: "ml fyp",
    },
  ];

  const reviews = [
    {
      img: "../images/person01.jpg",
      name: "Akeel Mohamed",
      review:
        "LogicCodex exceeded my expectations! They developed a fantastic web application for my business that not only looks great but also functions flawlessly. Their team is highly skilled, and their commitment to delivering quality is evident. I'm thrilled with the results!",
    },
    {
      img: "../images/person02.jpg",
      name: "Gihan Rodrigo",
      review:
        "I had the pleasure of working with LogicCodex to create a mobile application for my startup. The app they developed is incredibly user-friendly and works seamlessly across different platforms. Their attention to detail and dedication to providing a top-notch user experience are truly impressive.",
    },
    {
      img: "../images/person03.jpg",
      name: "Ramesh Liyanage",
      review:
        "I recently hired LogicCodex to develop a web application for my business, and I couldn't be happier with the results. Their team crafted a responsive and user-friendly app that perfectly suits our needs. The modern design and seamless functionality have significantly improved our online presence and customer engagement. I highly recommend LogicCodex for anyone looking to develop top-notch web applications.",
    },
    {
      img: "../images/person04.jpg",
      name: "Dashartha Hasaranga",
      review:
        "I collaborated with LogicCodex on an IoT project, and I must say they are true innovators in this field. Their engineering expertise in connecting devices and systems is unparalleled. They designed a solution that has revolutionized our automation and provided valuable data insights. If you're looking to transform your business with IoT, LogicCodex is the partner you need.",
    },
    
  ];

  const logos = [
    {
      img: "../images/logo01.png",
    },
    {
      img: "../images/logo02.png",
    },
    {
      img: "../images/logo03.png",
    },
    {
      img: "../images/logo01.png",
    },
    {
      img: "../images/logo02.png",
    },
    {
      img: "../images/logo04.png",
    },
    {
      img: "../images/logo05.png",
    },
    {
      img: "../images/logo06.png",
    },

   

    // Add more logo images as needed
  ];

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const scrollContainer = useRef(null);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = Array.from(scrollContainer.current.children);
      const scrollPosition = window.scrollY;

      // Find the section currently in view
      const currentSection = sections.find((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        return (
          scrollPosition >= sectionTop - sectionHeight / 2 &&
          scrollPosition < sectionTop + sectionHeight / 2
        );
      });

      // Update the active section
      setActiveSection(currentSection ? currentSection.id : null);
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pushInContainer = useRef();

  useLayoutEffect(() => {
    const options = {
      // Optionally pass settings to PushIn here (See API documentation).
    };

    const pushIn = new PushIn(pushInContainer.current, options);
    pushIn.start();
    return () => pushIn.destroy();
  });

  const [counterOn, setCounterOn] = useState(false);

  const openWhatsApp = () => {
    const number = "+94766762396"; // Replace with the desired phone number

    const message =
      "Check out this chat/profile: https://example.com/chat/" + number;
    const encodedMessage = encodeURIComponent(message);

    window.open(`https://wa.me/${number}?text=${encodedMessage}`);
  };

  const openMessenger = () => {
    const userID = "1234567890"; // Replace with the desired user ID or thread ID

    window.open(`https://www.messenger.com/t/${userID}`);
  };

  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleEmailClick = () => {
    const email = "example@example.com"; // Replace with your email address
    const subject = "Hello";
    const body = "Dear recipient,";

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowImage(true);
    }, 10000); // 10 seconds in milliseconds

    return () => clearTimeout(timeoutId);
  }, []);

  //email send

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function sendMail(e) {
    e.preventDefault();

    const params = {
      name: fname + " " + lname,
      email: email,
      subject: subject,
      message: message,
    };

    const serviceID = "service_kuf2aio";
    const templateID = "template_dnl0ntg";
    const userID = "9dVFHkBvJ1rBXbH1I";

    emailjs
      .send(serviceID, templateID, params, userID)
      .then((res) => {
        setFName("");
        setLName("");
        setSubject("");
        setEmail("");
        setMessage("");
        console.log(res);
        alert("Your message sent successfully!");
      })
      .catch((err) => console.log(err));
  }

  return (
    <div
      className="body"
      data-spy="scroll"
      data-target=".site-navbar-target"
      data-offset="300"
    >
      {/* Maintain On Box */}
      {/* <div className="buildbox">
        <div className="buildmgbox">
          <div className="col-12 percen mt-2">
            <img className="buildgif" src="../images/seting.gif" alt="" />
          </div>
          <div className="col-12 percen mt-2">
            <span className="buildtx">"Website under maintenance! 
            <br />
            <span className="buildtx2">Please check back later."</span></span>
          </div>
        </div>
      </div> */}
      {/* Maintain On Box */}

      {/* Loding   */}
      <div id="overlayer"></div>
      <div className="loader">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
      {/* Loding   */}

      {/* chat bot */}
      <div>
        {showImage && (
          <img
            onClick={handleButtonClick}
            className="chatbot"
            src="../images/chatbot2.gif"
            alt=""
          />
        )}

        {isOpen && (
          <div ref={divRef} className="boxchoosop row thinking-cloud">
            <div className="col-12 shocenter">
              <img
                onClick={openWhatsApp}
                className="iconsho "
                src="../images/whatsapp.png"
                alt=""
              />
              <img
                onClick={openMessenger}
                className="iconsho2"
                src="../images/massenger.png"
                alt=""
              />
            </div>
          </div>
        )}
      </div>
      {/* chat bot */}

      <header
        className="site-navbar py-4 js-sticky-header site-navbar-target"
        role="banner"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-xl-2">
              {/* <h1 className="mb-0 site-logo"> */}
              <a href="" className="h2 mb-0">
                <img className="mb-0 site-logo" src="../images/logo6.png"></img>
              </a>
              {/* </h1> */}
            </div>

            <div className="col-12 col-md-10 d-none d-xl-block">
              <nav
                className="site-navigation position-relative text-right"
                role="navigation"
              >
                <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                  <li
                    className={activeSection === "home-section" ? "active" : ""}
                  >
                    <a href="#home-section" className="">
                      <span>Home</span>
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "about-section" ? "actived" : ""
                    }
                  >
                    <a href="#about-section" className="">
                      <span>About Us</span>
                    </a>
                  </li>

                  <li
                    className={
                      activeSection === "portfolio-section" ? "actived" : ""
                    }
                  >
                    <a href="#portfolio-section" className="">
                      <span>Our Projects</span>
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "services-section" ? "actived" : ""
                    }
                  >
                    <a href="#services-section" className="">
                      <span>Services</span>
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "testimonials-section" ? "actived" : ""
                    }
                  >
                    <a href="#testimonials-section" className="">
                      <span>Review</span>
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "careers-section" ? "actived" : ""
                    }
                  >
                    <a href="/Careers" className="">
                      <span>Careers</span>{" "}
                    </a>
                  </li>
                  <li
                    className={
                      activeSection === "contact-section" ? "actived" : ""
                    }
                  >
                    <a href="#contact-section" className="">
                      <span>Contact</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-6 d-inline-block d-xl-none ml-md-0 py-3 UPstyle1">
              <a
                href="#"
                className="site-menu-toggle js-menu-toggle float-right"
              >
                <span className="icon-menu h3"></span>
              </a>
            </div>
          </div>
        </div>
      </header>

      <div className="site-wrap" ref={scrollContainer}>
        <div className="site-mobile-menu site-navbar-target">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle"></span>
            </div>
          </div>
          <div className="site-mobile-menu-body"></div>
        </div>

        <div
          className={
            activeSection === "about-section"
              ? "UPstyle2 site-blocks-cover overlay activescro"
              : "UPstyle2 site-blocks-cover overlay "
          }
          data-aos="fade"
          id="home-section"
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-8 mt-lg-5 text-center">
                <h1
                  className="text-uppercase"
                  data-aos="fade-down"
                  data-aos-delay="900"
                >
                  Welcome
                </h1>
                <p className="desc" data-aos="fade-left" data-aos-delay="1000">
                  Welcome to <span className="logitx">LOGIC</span>
                  <span className="logitx2">CODEX</span> , Sri Lanka's leading
                  software engineering company.
                </p>
                <p
                  className="mb-5 desc"
                  data-aos="fade-right"
                  data-aos-delay="1000"
                >
                  Please contact us for any service you require.
                </p>
                <div data-aos="fade-up" data-aos-delay="1000">
                  <a
                    href="#contact-section"
                    className="btn smoothscroll btn-success mr-2 mb-2 contackhover txbtn"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-section cta-big-image" id="about-section">
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 text-center" data-aos="fade">
                <h2 className="section-title mb-3 avus">About Us</h2>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-6 mb-5"
                data-aos="fade-up"
                data-aos-delay=""
              >
                <figure className="circle-bg hovercircl">
                  <img
                    src="../images/png02.jpg"
                    alt="Image"
                    className="img-fluid boroll"
                  />
                </figure>
              </div>
              <div
                className="col-lg-5 ml-auto"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="mb-4">
                  <h3 className="h3 mb-4 text-black">Welcome to Logiccodex!</h3>
                  <p className="tx_justy">
                    &emsp;At Logiccodex, we are more than just a software
                    company - we are your partners in innovation. With a passion
                    for technology and a commitment to excellence, we create
                    cutting-edge software solutions that empower businesses to
                    thrive in the digital age.
                    <br />
                    <br />
                    &emsp;Our team of skilled developers, designers, and
                    strategists work collaboratively to craft software that not
                    only meets your needs but exceeds your expectations. From
                    conceptualization to implementation, we are dedicated to
                    delivering high-quality, tailor-made solutions that drive
                    growth and efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="site-section cta-big-image" id="about-section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-5 ml-auto"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="mb-4">
                  <h3 className="h3 mb-4 text-black">Why choose Logiccodex?</h3>
                  <p className="tx_justy">
                    Discover how technology can transform your business. Join
                    hands with Logiccodex and embark on a journey of digital
                    transformation that opens up new possibilities.
                  </p>
                </div>

                <div className="mb-4">
                  <ul className="list-unstyled ul-check success">
                    <li>Innovation at Heart</li>
                    <li>Customized Approach</li>
                    <li>User-Centric Design</li>
                    <li>Reliability and Support</li>
                    <li>Expert Team</li>
                    <li>Wide Range of Services</li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 mb-5"
                data-aos="fade-up"
                data-aos-delay=""
              >
                <figure className="circle-bg2 hovercircl">
                  <img
                    src="../images/about_1.jpg"
                    alt="Image"
                    className="img-fluid boroll"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>

        <section className="site-section" id="about-section">
          <div className="container">
            <div className="row mb-5 justify-content-center">
              <div className="col-md-7 text-center">
                <h2
                  className="section-title mb-3 avus"
                  data-aos="fade-up"
                  data-aos-delay=""
                >
                  Our Features
                </h2>
                <p className="lead" data-aos="fade-up" data-aos-delay="100">
                  Equip your business for the digital era with Logiccodex. Our
                  features enhance efficiency, boost productivity, and drive
                  innovation. Here's why we stand out:
                </p>
              </div>
            </div>

            <div className="row">
              <div
                className="col-lg-6 mb-5 phonebox1"
                data-aos="fade-up"
                data-aos-delay=""
              >
                <div className="owl-carousel slide-one-item-alt">
                  <img
                    src="../images/Features01.png"
                    alt="Image"
                    className="img-fluid"
                  />
                  <img
                    src="../images/Features02.png"
                    alt="Image"
                    className="img-fluid"
                  />
                  <img
                    src="../images/Features03.png"
                    alt="Image"
                    className="img-fluid"
                  />
                  <img
                    src="../images/Features04.png"
                    alt="Image"
                    className="img-fluid"
                  />
                </div>
                <div className="custom-direction">
                  <a href="#" className="custom-next display04">
                    <img
                      className="arrows"
                      src="../images/lefticon.png"
                      alt=""
                    />
                  </a>

                  <a href="#" className="custom-prev display05">
                    <img
                      className="arrows"
                      src="../images/lefticon.png"
                      alt=""
                    />
                  </a>
                  <a href="#" className="custom-next display01">
                    <img
                      className="arrows"
                      src="../images/rightarror.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div
                className="col-lg-5 ml-auto phonebox2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="custom-direction2">
                  <a href="#" className="custom-prev display03">
                    <img
                      className="arrows"
                      src="../images/rightarror.png"
                      alt=""
                    />
                  </a>
                </div>

                <div className="owl-carousel slide-one-item-alt-text">
                  <div>
                    <h2 className="section-title mb-5 actr">
                      Intuitive User Experience
                    </h2>

                    <p className="tx_justy">
                      Navigating technology shouldn't be a struggle. Our
                      commitment to an intuitive user experience means that
                      every interaction with our software is designed with
                      simplicity in mind. No extensive training is required –
                      your team can hit the ground running, embracing the full
                      functionality of our tools effortlessly. This translates
                      to increased productivity, reduced learning curves, and an
                      enhanced user experience that drives business success.
                    </p>
                  </div>

                  <div>
                    <h2 className="section-title mb-5 actr">
                      Scalability with Ease
                    </h2>

                    <p className="tx_justy">
                      We understand that your business is on a journey of
                      growth. Our software solutions have been architected to
                      evolve alongside your company, ensuring that the tools you
                      depend on can handle increased demands without a hitch. As
                      your operations expand, our software seamlessly adapts,
                      allowing you to focus on expanding your horizons while we
                      take care of the technology foundation.
                    </p>
                  </div>

                  <div>
                    <h2 className="section-title mb-5 actr">
                      Data-Driven Insights
                    </h2>

                    <p className="tx_justy">
                      Informed decisions lead to better outcomes. Our software
                      empowers you with data-driven insights that provide a
                      comprehensive view of your operations and customer
                      behaviors. By gathering, analyzing, and presenting key
                      metrics, you gain the tools needed to make strategic
                      choices that are rooted in data. This invaluable resource
                      transforms decision-making from guesswork to a
                      well-informed science.
                    </p>
                  </div>

                  <div>
                    <h2 className="section-title mb-5 actr">
                      Seamless Integration
                    </h2>

                    <p className="tx_justy">
                      Disconnected systems can hinder productivity and
                      collaboration. That's why we've prioritized seamless
                      integration as a cornerstone of our software solutions.
                      Our technology effortlessly integrates with your existing
                      tools and processes, creating a cohesive and streamlined
                      workflow. This integration eliminates the barriers between
                      different aspects of your operations, promoting efficiency
                      and enabling teams to collaborate effortlessly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="site-section border-bottom" id="about-section">
          <div className="container">
            <div className="row mb-5 justify-content-center">
              <div className="col-md-8 text-center">
                <h2
                  className="section-title mb-3 avus"
                  data-aos="fade-up"
                  data-aos-delay=""
                >
                  Our Team
                </h2>
                <p className="lead" data-aos="fade-up" data-aos-delay="200">
                Tempor erat elitr rebum it. Aliqu diam amet labore. Clita erat ipsum et lorem et situo justo magna dolore erat amet
                </p>
              </div>
            </div>
            <div className="row">
              {tearm.map((tearm) => (
                <div
                  className="col-md-6 col-lg-3 mb-4"
                  data-aos="fade-up"
                  data-aos-delay=""
                >
                  <div className="team-member">
                    <figure>
                      <ul className="social">
                        <li>
                          <a href={tearm.facebook}>
                            <span className="icon-facebook iconup"></span>
                          </a>
                        </li>
                        <li>
                          <a href={tearm.twitter}>
                            <span className="icon-twitter iconup"></span>
                          </a>
                        </li>
                        <li>
                          <a href={tearm.linkedin}>
                            <span className="icon-linkedin iconup"></span>
                          </a>
                        </li>
                        <li>
                          <a href={tearm.instagram}>
                            <span className="icon-instagram iconup"></span>
                          </a>
                        </li>
                      </ul>
                      <img src={tearm.img} alt="Image" className="img-fluid" />
                    </figure>
                    <div className="p-3">
                      <h3>{tearm.name}</h3>
                      <span className="position">{tearm.role}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section> */}

        <section className="site-section section" id="portfolio-section">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12 text-center" data-aos="fade">
                <h2 className="section-title mb-3 avus">Our Projects</h2>
                <span className="tx_red">
                  All the projects we have done cannot be shown here due to
                  privacy concerns and please{" "}
                  <a href="#contact-section">contact us</a> for more
                  information.
                </span>
              </div>
            </div>

            <div className="row justify-content-center mb-5" data-aos="fade-up">
              <div
                id="filters"
                className="filters text-center button-group col-md-10"
              >
                <button className="btn btn-primary  active" data-filter="*">
                  All
                </button>
                <button className="btn btn-primary" data-filter=".web">
                  Web
                </button>
                <button className="btn btn-primary" data-filter=".app">
                  App
                </button>
                <button className="btn btn-primary" data-filter=".ml">
                  ML
                </button>
                <button className="btn btn-primary" data-filter=".fyp">
                  FYP CONSULTANT
                </button>
                <button className="btn btn-primary" data-filter=".iot">
                  IOT
                </button>
                <button className="btn btn-primary" data-filter=".assignment">
                  ASSIGNMENT
                </button>
                <button className="btn btn-primary" data-filter=".uiux">
                  UX/UI
                </button>
              </div>
            </div>

            <div id="posts" className="row no-gutter">
              {product.map((product) => (
                <div
                  className={`${product.status} item  col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-5`}
                >
                  <div className="col-12 mb-5">
                    <div className="row bg-white ms-2 me-2 borderboxpro">
                      <div className="col-12">
                        <div className="row">
                          <Carousel>
                            <div>
                              <a
                                href={product.img1}
                                className="item-wrap fancybox"
                                data-fancybox="gallery2"
                              >
                                <img className="bor" src={product.img1} />
                              </a>
                            </div>
                            <div>
                              <a
                                href={product.img2}
                                className="item-wrap fancybox"
                                data-fancybox="gallery2"
                              >
                                <img className="bor" src={product.img2} />
                              </a>
                            </div>
                            <div>
                              <a
                                href={product.img3}
                                className="item-wrap fancybox"
                                data-fancybox="gallery2"
                              >
                                <img className="bor" src={product.img3} />
                              </a>
                            </div>
                            <div>
                              <a
                                href={product.img4}
                                className="item-wrap fancybox"
                                data-fancybox="gallery2"
                              >
                                <img className="bor" src={product.img4} />
                              </a>
                            </div>
                          </Carousel>
                        </div>
                        <div className="upboxtop">
                          <div className="col-12 centermid">
                            <span className="productx01">{product.title}</span>
                          </div>
                          <div className="col-12 centermid mt-2">
                            <span
                              className="productx02"
                              dangerouslySetInnerHTML={{
                                __html: product.description,
                              }}
                            />
                          </div>
                        </div>
                        <div className="row marlefti">
                          <div className="col-12 centermid">
                            <a
                              onClick={openWhatsApp}
                              href=""
                              className="btn smoothscroll btn-success mr-2 mb-2 contackhover txbtn"
                            >
                              <span>More Detaile</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          className="site-section border-bottom bg-light"
          id="services-section"
        >
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 text-center" data-aos="fade">
                <h2 className="section-title mb-3 avus">Our Services</h2>
              </div>
            </div>
            <div className="row align-items-stretch">
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                data-aos="fade-up"
              >
                <div className="unit-4 bgboxcolor">
                  <div className="unit-4-icon mr-4 centerall ">
                    <div className="imagema">
                      <img src="../images/webapp.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="centerall">
                      <h3>Web Applications</h3>
                    </div>
                    <div className="centerall">
                      <p>
                      Web Applications: Craft responsive, user-friendly web apps tailored to your needs, featuring modern design and seamless functionality.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="unit-4 bgboxcolor">
                  <div className="unit-4-icon mr-4 centerall ">
                    <div className="imagema">
                    <img src="../images/deskapp.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="centerall">
                      <h3>Desktop Applications</h3>
                    </div>
                    <div className="centerall">
                      <p>
                      Desktop Applications: Build robust desktop software with intuitive interfaces for enhanced productivity and user satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="unit-4 bgboxcolor">
                  <div className="unit-4-icon mr-4 centerall ">
                    <div className="imagema">
                    <img src="../images/mobapp.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="centerall">
                      <h3>Mobile Applications</h3>
                    </div>
                    <div className="centerall">
                      <p>
                      Mobile Applications: Develop cutting-edge mobile apps across platforms, delivering exceptional user experiences and performance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                data-aos="fade-up"
                data-aos-delay=""
              >
                <div className="unit-4 bgboxcolor">
                  <div className="unit-4-icon mr-4 centerall ">
                    <div className="imagema">
                    <img src="../images/iot.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="centerall">
                      <h3>IOT Projects</h3>
                    </div>
                    <div className="centerall">
                      <p>
                      IoT Projects: Engineer innovative Internet of Things solutions, connecting devices and systems to revolutionize automation and data insights.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="unit-4 bgboxcolor">
                  <div className="unit-4-icon mr-4 centerall ">
                    <div className="imagema">
                    <img src="../images/ml.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="centerall">
                      <h3>ML Models</h3>
                    </div>
                    <div className="centerall">
                      <p>
                      ML Models: Create powerful machine learning models to analyze and predict data, driving data-driven decision-making and automation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 mb-4 mb-lg-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="unit-4 bgboxcolor">
                  <div className="unit-4-icon mr-4 centerall ">
                    <div className="imagema">
                    <img src="../images/uiux.png" alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="centerall">
                      <h3>UI/UX Designs</h3>
                    </div>
                    <div className="centerall">
                      <p>
                      UI/UX Designs: Design stunning user interfaces and engaging user experiences, ensuring your products captivate and retain users effectively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ScrollTrigger
          id="about-section"
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <section class="us_section layout_padding">
            <div class="container">
              <div class="heading_container">
                <h2>Why Choose Us</h2>
              </div>

              <div class="us_container">
                <div className="col-12 centera">
                  <div class="box boxleri">
                    <div class="img">
                      <svg
                        className="choisicon"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 640 512"
                      >
                        <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                      </svg>
                    </div>
                    <div class="img-box">
                      <p className="numyrlow">
                        {counterOn && (
                          <CountUp start={0} end={500} duration={5} delay={0} />
                        )}
                        +
                      </p>
                    </div>
                    <div class="detail-box">
                      <h5>Happy Clients</h5>
                    </div>
                  </div>
                  <div class="box boxleri">
                    <div class="img">
                      <svg
                        className="choisicon2"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                      </svg>
                    </div>
                    <div class="img-box">
                      <p className="numyrlow">
                        {counterOn && (
                          <CountUp start={0} end={150} duration={5} delay={0} />
                        )}
                        +
                      </p>
                    </div>
                    <div class="detail-box">
                      <h5>Project</h5>
                    </div>
                  </div>
                  <div class="box boxleri">
                    <div class="img">
                      <svg
                        className="choisicon2"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                      </svg>
                    </div>
                    <div class="img-box">
                      <p className="numyrlow">
                        {counterOn && (
                          <CountUp start={0} end={200} duration={5} delay={0} />
                        )}
                        +
                      </p>
                    </div>
                    <div class="detail-box">
                      <h5>Reviews</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ScrollTrigger>

        <section
          className="site-section testimonial-wrap"
          id="testimonials-section"
          data-aos="fade"
        >
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 text-center">
                <h2 className="section-title mb-3 avus">Customer Review</h2>
              </div>
            </div>
          </div>
          <div className="slide-one-item home-slider owl-carousel">
            {reviews.map((reviews) => (
              <div>
                <div className="testimonial">
                  <blockquote className="mb-5">
                    <p>&ldquo;{reviews.review}&rdquo;</p>
                  </blockquote>

                  <figure className="mb-4 d-flex align-items-center justify-content-center">
                    <div>
                      <img
                        src={reviews.img}
                        alt="Image"
                        className="w-50 img-fluid mb-3"
                      />
                    </div>
                    <p>{reviews.name}</p>
                  </figure>
                </div>
              </div>
            ))}
          </div>

          <div className="logobox">
            <div className="col-12">
              <div>
                <Slider {...settings}>
                  {logos.map((logo, index) => (
                    <div key={index}>
                      <img className="logow" src={logo.img} />
                      {/* <img className="" src="../images/logo6.png"></img> */}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/* </div> */}
        </section>
        <section
          className="site-section testimonial-wrap"
          id="careers-section"
          data-aos="fade"
        >
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 text-center">
                <h2 className="section-title mb-3 avus">Careers</h2>
              </div>
            </div>
          </div>
          <div className="row margincarrer">
            <div className="col-12 col-md-4 mt-2 mb-2 all_center">
              <div className="white-box">
                <img
                  className="box-image"
                  src="../images/career_1.png"
                  alt="Box Image"
                />
                <span className="boxtitle">Candidates</span>
                <div className="box-text">
                  Explore job opportunities and join our talented team to shape
                  the future together. We offer a dynamic work environment and
                  exciting projects that will challenge and inspire you.
                </div>
                <a href="/Careers">
                  <div className="view-more-button">View More</div>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-2 mb-2 all_center">
              <div className="white-box">
                <img
                  className="box-image2"
                  src="../images/career_2.png"
                  alt="Box Image"
                />
                <span className="boxtitle">Internship Opportunities</span>
                <div className="box-text">
                Join our talented team as an intern and gain hands-on experience in a dynamic work environment. We offer exciting projects that will challenge and inspire you, providing a valuable stepping stone for your career.
                </div>
                <a href="/Careers">
                  <div className="view-more-button">View More</div>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-2 mb-2 all_center">
              <div className="white-box">
                <img
                  className="box-image3"
                  src="../images/career_3.png"
                  alt="Box Image"
                />
                <span className="boxtitle">Executive jobs</span>
                <div className="box-text">
                  Lead our executive team and drive success across the
                  organization. We are looking for visionary leaders who can
                  shape strategy, inspire teams, and deliver outstanding
                  results.
                </div>
                <a href="/Careers">
                  <div className="view-more-button">View More</div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section
          className="site-section bg-light"
          id="contact-section"
          data-aos="fade"
        >
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 text-center">
                <h2 className="section-title mb-3 avus">Contact Us</h2>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-4 text-center">
                <p className="mb-4">
                  <span className=" avus2 icon-room d-block h4 "></span>
                  <span>Colombo, Sri Lanka</span>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <p className="mb-4">
                  <span className="avus2 icon-phone d-block h4 "></span>
                  <a className="avus2">+94 786718002</a>
                </p>
              </div>
              <div className="col-md-4 text-center">
                <p className="mb-0">
                  <span className=" avus2 icon-mail_outline d-block h4"></span>
                  <a className="avus2" onClick={handleEmailClick}>
                    logiccodex@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-5">
                <form className="p-5 bg-white routerbor" onSubmit={sendMail}>
                  <h2 className="h4 text-black mb-5">Contact Form</h2>

                  <div className="row form-group">
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label className="text-black" for="fname">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="fname"
                        className="form-control"
                        value={fname}
                        onChange={(e) => setFName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="text-black" for="lname">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="lname"
                        className="form-control"
                        value={lname}
                        onChange={(e) => setLName(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black" for="email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black" for="subject">
                        Subject
                      </label>
                      <input
                        type="subject"
                        id="subject"
                        className="form-control"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-md-12">
                      <label className="text-black" for="message">
                        Message
                      </label>
                      <textarea
                        id="message"
                        cols="30"
                        rows="7"
                        className="form-control"
                        placeholder="Write your notes or questions here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div className="row form-group">
                    <div className="col-md-12">
                      <input
                        type="submit"
                        value="Send Message"
                        className="btn btn-md text-white btngreen mt-2"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="info_section layout_padding2-top">
          {/* <div class="container">
            <div class="info_form">
              <h4>Our subscribes</h4>
              <form action="">
                <input type="text" placeholder="Enter your email" />
                <div class="d-flex justify-content-end">
                  <button>subscribe</button>
                </div>
              </form>
            </div>
          </div> */}
          <div class="container">
            <div class="row">
              <div class="col-md-4">
                <h6>About Us</h6>
                <p className="tx_justy">
                  At Logiccodex, we're dedicated to driving innovation through
                  technology. With a passion for excellence, we craft software
                  solutions that empower businesses to thrive in the digital
                  age. Our team of experts is committed to delivering
                  tailor-made, user-centric products that streamline operations
                  and foster growth. Join us on this journey of transformation
                  and discover the power of cutting-edge software.
                </p>
              </div>
              <div class="col-md-3 offset-md-1">
                <h6>Menus</h6>
                <ul>
                  <li class=" active">
                    <a class="" href="#">
                      Home <span class="sr-only">(current)</span>
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#about-section">
                      About Us{" "}
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#portfolio-section">
                      Our Projects
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#services-section">
                      Services{" "}
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#testimonials-section">
                      Review
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="/Careers">
                      Careers
                    </a>
                  </li>
                  <li class="">
                    <a class="" href="#contact-section">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-md-4">
                <h6>Contact Us</h6>
                <div class="info_link-box">
                  <a>
                    <span className="icon-room d-block h5  mt-2"></span>
                    <span className="amrlef">Colombo, Sri Lanka</span>
                  </a>
                  <a>
                    <span className="icon-phone d-block h5  mt-2"></span>
                    <span className="amrlef">+94 786718002</span>
                  </a>
                  <a>
                    <span className="icon-mail_outline d-block h5  mt-2"></span>
                    <span className="amrlef"> logiccodex@gmail.com</span>
                  </a>
                </div>
                <div class="info_social">
                  <div className="bg_rool">
                    <a
                      href="https://www.facebook.com/UniversityLifeOfficiall12?mibextid=ZbWKwL"
                      className="pt-2"
                    >
                      <span className="icon-facebook iconcolofoot"></span>
                    </a>
                  </div>
                  <div className="bg_rool">
                    <a href="#" className="pt-1">
                      <span className="icon-twitter iconcolofoot"></span>
                    </a>
                  </div>
                  <div className="bg_rool">
                    <a
                      href="https://instagram.com/logiccodex?igshid=MzRlODBiNWFlZA=="
                      className="pt-1"
                    >
                      <span className="icon-instagram iconcolofoot"></span>
                    </a>
                  </div>
                  <div className="bg_rool">
                    <a href="#" className="pt-1">
                      <span className="icon-linkedin iconcolofoot"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="container-fluid footer_section ">
          <p>
            &copy; 2023 All Rights Reserved.
            <a href="#"> LOGICCODEX</a>
          </p>
        </section>
      </div>
    </div>
  );
}

export default Home;
